import moment from "moment";
import Swal from "sweetalert2";

function dateParser(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}
function dateConvertUTC7(date) {
  var utcDate = new Date(date);
  var utcTime = utcDate.getTime();
  var offset = 7 * 60 * 60 * 1000;
  var utcPlus7Time = utcTime + offset;
  var utcPlus7Date = new Date(utcPlus7Time);

  return utcPlus7Date;
}
function capitalizeText(string) {
  if (string.trim()) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } else {
    return "-";
  }
}
export async function confirmAction(
  title,
  subtitle,
  button_text,
  theme,
  is_input = false,
  default_input = ""
) {
  try {
    const result = await Swal.fire({
      title: title || "Apakah Anda Yakin?",
      html: `<div class="mb-2">${subtitle || ""}</div>`,
      icon: theme || "warning",
      ...(is_input
        ? {
            input: "textarea",
            inputAttributes: {
              autocapitalize: "off",
              rows: 3,
            },
            inputValue: default_input || "",
          }
        : {}),
      showCancelButton: true,
      confirmButtonText: button_text || "Ya!",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-info",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    });
    if (is_input) {
      return result.value;
    } else {
      return result.isConfirmed;
    }
  } catch (error) {
    return false;
  }
}
export function showActionResult(text, theme) {
  Swal.fire({
    html: text || "",
    icon: theme || "success",
  });
}
export function getErrorMessage(err) {
  if (err && err.response && err.response.data) {
    return (
      err.response.data.detail?.message || "Terjadi Kesalahan Pada Sistem!"
    );
  }
  return "Terjadi Kesalahan Pada Sistem!";
}
export function showLoaderAlert() {
  Swal.fire({
    title: "Memperoses Data!",
    html: "Sistem Sedang Memproses Data, Mohon Tunggu Sebentar ^_^",
    allowOutsideClick: false,
    timer: 100000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
      const timer = Swal.getPopup().querySelector("b");
    },
  });
}
export function closeAlert() {
  Swal.close();
}
export default {
  dateParser,
  dateConvertUTC7,
  capitalizeText,
};
